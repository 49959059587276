
import request from '../request'

export function fetchList (params) {
    return request({
        url: '/bim/task/list',
        params,
    })
}

export function add (data) {
    return request({
        url: '/bim/task/add',
        method: 'post',
        data,
    })
}

export function kill(data) {
    return request({
        url: '/bim/task/kill',
        method: 'post',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/bim/task/query/' + params.id,
    })
}

export function fetchValues(data) {
    return request({
        url: '/bim/item/query/formula',
        method: 'post',
        data,
    })
}