
import request from './request'

export function fetchList (params) {
    return request({
        url: '/bim/project/list',
        params,
    })
}

export function add (data) {
    return request({
        url: '/bim/project/add',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/bim/project/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/bim/project/delete',
        method: 'post',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/bim/project/query/' + params.id,
    })
}