<template>
  <div class="card">
    <div class="query">
      <select placeholder="项目名称" v-model="projectId">
        <option v-for="item in projectList" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>

      <input type="text" placeholder="任务名称" v-model="name" />

      <select placeholder="任务状态" v-model="status">
        <option
          v-for="item in statusList"
          :key="item.value"
          :value="item.value"
        >
          {{ item.name }}
        </option>
      </select>

      <div class="button-group">
        <button class="primary-button" @click="query">查询</button>
        <button class="reset-button" @click="reset">重置</button>
      </div>

      <button class="add" @click="save">创建任务</button>
    </div>

    <a-skeleton v-if="loading" />
    <div v-else>
      <a-empty v-if="list.length === 0" />

      <div v-else>
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id">
            <div class="header">
              <img class="avatar" :src="item.modelImg" alt />
              <DataDictFinder
                dictType="bim_task_status"
                :dictValue="item.status"
                iconType="badge"
              />
            </div>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div style="display: flex;align-items: baseline;">
                <div class="title" :title="item.name" style="max-width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ item.name }} </div>
              </div>
              <div class="title" style="font-size: 12px;">运行时间:{{ renderTime(item.updateAt,item.createAt)}}</div>
            </div>
            <div class="progress-bar">
              <div
                class="progress"
                :style="{ width: item.progress ? item.progress : '0' + '%' }"
              ></div>
            </div>

            <div class="footer">
              <div class="date">{{ renderDate(item.createAt) }}</div>

              <a-tooltip title="修改名称">
                <img
                  src="@/assets/icons/edit.png"
                  alt
                  @click="editName(item)"
                />
              </a-tooltip>

              <a-tooltip title="参数">
                <img
                  src="@/assets/icons/info.png"
                  alt
                  @click="openDetail(item)"
                />
              </a-tooltip>

              <a-tooltip title="预览">
                <img src="@/assets/icons/scan.png" alt @click="preview(item)" />
              </a-tooltip>
              <a-tooltip title="下载">
                <img
                  src="@/assets/icons/download.png"
                  alt
                  @click="download(item)"
                />
              </a-tooltip>
              <a-tooltip title="删除" v-if="item.status !== 'running' && item.status !== 'fileUploading'">
                <img
                  src="@/assets/icons/remove.png"
                  alt
                  @click="remove(item)"
                />
              </a-tooltip>
              <a-tooltip
                title="终止任务"
                v-if="
                  item.status !== 'deleted' &&
                  item.status !== 'cancel' &&
                  item.status !== 'finished'
                "
              >
                <img
                  src="@/assets/icons/kill-active.png"
                  alt
                  @click="stop(item)"
                />
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="right" style="margin-top: 12px">
          <a-pagination v-model="current" :total="total" :pageSize="pageSize"/>
        </div>
      </div>
    </div>

    <a-modal
      title="请选择项目"
      :visible="addVisible"
      :footer="null"
      @cancel="cancel"
    >
      <div style="margin-bottom: 16px">
        <a-select
          v-model="selectedProjectId"
          style="width: 100%"
          placeholder="请选择项目"
        >
          <a-select-option
            v-for="item in projectList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-modal>

    <a-modal
      title="修改名称"
      :visible="editNameDialogShow"
      :footer="null"
      @cancel="editNameDialogShow = false"
    >
      <div style="margin-bottom: 16px">
        <a-input v-model="editDetial.name"/>
      </div>

      <div class="right">
        <a-space>
          <a-button @click="editNameDialogShow = false">取消</a-button>
          <a-button type="primary" @click="updateName" :loading="btnLoading">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
function update(data) {
  return request({
      url: '/bim/task/update/name',
      method: 'POST',
      data
  })
}
function addLog(data) {
  return request({
      url: '/bim/optLog/add',
      method: 'POST',
      data
  })
}
function removeObj(data) {
  return request({
      url: '/bim/task/delete',
      method: 'POST',
      data
  })
}
function checkDownload(param) {
  return request({
      url: '/bim/taskFile/download/' + param,
  })
}
import request from '@/api/request.js'
import { mapGetters } from "vuex";
import { fetchList, kill } from "@/api/task";
import { fetchList as fetchProjectList } from "@/api/project";
import moment from "moment";
export default {
  data() {
    return {
      btnLoading: false,
      editNameDialogShow: false,
      editDetial: {},
      projectId: undefined,
      name: "",
      status: undefined,

      loading: false,
      current: 1,
      pageSize: 12,
      list: [],
      total: 0,

      projectList: [],
      addVisible: false,
      selectedProjectId: undefined,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("bim_task_status");
    },
  },

  watch: {
    current() {
      this.getList();
    },
    projectId() {
      this.getList();
    },
  },
  destroyed() {
    console.log("clear timer");
    clearInterval(this.timer);
  },
  mounted() {
    this.loading = true;
    fetchProjectList({
      pageNum: 1,
      pageSize: 999,
    })
      .then((res) => {
        console.log("res", res);
        if (Array.isArray(res.list)) {
          this.projectList = res.list;
          if (res.list.length > 0) {
            const { projectId, name } = this.$route.query;
            if (projectId) {
              this.projectId = projectId;
            } else {
              this.projectId = res.list[0].id;
            }

            if (name) {
              this.name = name;
            }
            this.getList();
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    renderDate(date) {
      return moment(date).format('yyyy-MM-DD HH:MM')
    },
    updateName() {
      this.btnLoading = true;
      update({
        id: this.editDetial.id,
        name: this.editDetial.name,
      }).then((res) => {
        console.log("res", res);
        this.editNameDialogShow = false;
        this.getList();
      })
      .finally(() => {
        this.btnLoading = false;
      });
    },
    editName(item) {
      this.editDetial = JSON.parse(JSON.stringify(item));
      this.editNameDialogShow = true;
    },
    renderTime(updateAt, createAt) {
      if(updateAt && createAt) {
        const diff  = moment(updateAt).diff(createAt, "minutes");
        const durationTime = moment.duration(diff, 'minutes');
        const minutes = durationTime.minutes();
        const hours = durationTime.hours();
        let result = `${minutes}分钟`;
        if (hours > 0) {
          result = `${hours}小时${result}`;
        }
        return result;
      }else{
        return "0分钟";
      }

    },
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        projectId: this.projectId,
        name: this.name,
        status: this.status,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
            clearInterval(this.timer);
            try {
              this.list.forEach(v=>{
                if(v.status == "running" || v.status == "queued" || v.status == "fileUploading") {
                  this.timer = setInterval(() => {
                    this.getList();
                    console.log("refresh message");
                  }, 1000 * 60);
                  throw new Error('退出');
                }
              })
            } catch(e) {
              console.log(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.projectId = undefined;
      this.name = "";
      this.status = undefined;
      this.getList();
    },

    add() {
      this.addVisible = true;
    },
    cancel() {
      this.addVisible = false;
    },

    save() {
      if (this.projectId) {
        window.localStorage.setItem(
          "selectedProjectId",
          this.projectId
        );
        this.$router.push(this.$route.path + "/add");
      } else {
        this.$message.error("请选择项目");
      }
    },

    openDetail(item) {
      this.$router.push(this.$route.path + "/detail?id=" + item.id);
    },

    preview(item) {
      if (item.localPreviewFile) {
        sessionStorage.setItem('fileUrls', JSON.stringify(item.localPreviewFile))
        this.$router.push(
          this.$route.path + "/preview"
        );
      } else {
        this.$message.error("没有预览文件");
      }
    },

    download(item) {
      checkDownload(item.id).then((res) => {
        console.log("res", res);
        if(res == null) {
          this.$message.error("没有下载文件");
          return
        }
        if (res.code != 400) {
          addLog({
            type: 'model_download',
            fid: item.id,
            fname: item.name
          });
          window.open(res);
        }
      })
      
    },
    
    remove(item) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          removeObj({
            id: item.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    stop(item) {
      const that = this;
      this.$confirm({
        title: "确认要结束任务吗？",
        onOk() {
          kill({
            id: item.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;

  .query {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;

    input,
    select {
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
    input::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #abb3c0;
    }
    .button-group {
      .primary-button {
        width: 71px;
        height: 38px;
        line-height: 100%;
        color: #fff;
        background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
        box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
        border-radius: 6px 0px 0px 6px;
      }

      .reset-button {
        width: 71px;
        height: 38px;
        background: #fff;
        border-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 3px solid #eff1f6;
        border-right: 3px solid #eff1f6;
        border-bottom: 3px solid #eff1f6;
        box-sizing: border-box;
      }
    }
  }

  .add {
    margin-left: auto;
    width: 156px;
    height: 38px;
    line-height: 100%;
    background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
    border-radius: 6px;
    color: #fff;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    max-height: 61vh;
    overflow: auto;
    .item {
      background: #fff;
      box-shadow: 0px 0px 13px 0px rgba(82, 131, 221, 0.1);
      border-radius: 6px;
      border: 2px solid #eff1f6;
      padding: 16px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .avatar {
          height: 60px;
          display: block;
        }

        .status {
          color: #818386;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 13px;
          .icon {
            margin-top: 0.5px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #818386;
            box-sizing: border-box;

            .dot {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #818386;
            }
          }
        }
        .active {
          color: #087afe;

          .icon {
            border-color: #087afe;

            .dot {
              background-color: #087afe;
            }
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #323232;
        margin-bottom: 16px;
      }

      .progress-bar {
        height: 6px;
        background: #dfe4e8;
        border-radius: 4px;
        margin-bottom: 24px;

        .progress {
          height: 6px;
          border-radius: 4px;
          background: #087afe;
        }
      }

      .footer {
        display: flex;
        align-items: center;
        .date {
          margin-right: auto;
          background: #f1f2f4;
          border-radius: 4px;
          padding: 0 6px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(96, 112, 137, 0.69);
        }

        img {
          height: 30px;
          margin-left: 6px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .card {
    .list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>